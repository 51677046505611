import React, { useState, useEffect } from 'react'; 
import ResultsDisplay from './ResultsDisplay';
import ShibesTables from './ShibesTable'; 
import './Homepage.css'; 


export const fetchShibesData = async () => {
    try {
        const response = await fetch('https://shibexer-backend-dace7862e022.herokuapp.com/api/shibesSupply');
        return response.json(); 
    } catch (error) {
        console.error('Error fetching shibes data:', error);
        return null;
    }
};

export const scaleUTXOs = async (wallet, wallet2) => {
    try {
        const response = await fetch('https://shibexer-backend-dace7862e022.herokuapp.com/api/splitUTXOs', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ wallet, wallet2 })
        });

        
        if (!response.ok) {
            const errorMsg = await response.text(); 
            throw new Error(errorMsg);
        }

        return await response.json();
        //console.log('Results scalling:', result);
    } catch (error) {
        console.error('Error scaling UTXOs', error)
    }
} 

function Homepage() { 
    const [isScanning, setIsScanning] = useState(false);
    const [requestId, setRequestId] = useState(null); 
    const [isCleared, setIsCleared] = useState(false);
    const [ updating ] = useState(false);
    
    // File Input
    const [address, setAddress] = useState('');
    const [dogeDetails, setDogeDetails] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [requestMade, setRequestMade] = useState(false); 

    const handleChange = (e) => {
        setAddress(e.target.value);
    };  

    const clearResults = () => {
        setDogeDetails(null);
        setIsCleared(true);
    };
  
    const startDogeDetailsProcessing = async () => {
        if (!address) {
            setError('No address provided!');
            return;
        }

        setLoading(true);
        setIsScanning(true);

        try { 
            const response = await fetch(`https://shibexer-backend-dace7862e022.herokuapp.com/api/startDogeDetailsProcessing?address=${address}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setRequestId(data.requestId);
            setError(null);
            setIsCleared(false);
            setRequestMade(true);
            pollForResult(data.requestId); // Start polling for results
        } catch (err) {
            setError('Error starting processing!');
        } finally {
            setLoading(false);
        }
    }

    const pollForResult = async (requestId) => {
        let interval;
        try {
            interval = setInterval(async () => {
                const response = await fetch(`https://shibexer-backend-dace7862e022.herokuapp.com/api/getDogeDetailsResults?requestId=${requestId}`);
                if (response.ok) {
                    const data = await response.json();
                    clearInterval(interval); // Stop polling
                    setDogeDetails(data);
                    setError(null);
                } else if (response.status !== 404) {
                    clearInterval(interval);
                    //setError('Error fetching results!');
                }
            }, 1000);
        } catch (error) {
            clearInterval(interval);
            //setError('Error fetching results!');
        }
    };    

    useEffect(() => {
        // const savedAddress = localStorage.getItem('walletAddress');
        // const savedPrivKey = localStorage.getItem('privKey');
        // const savedVerification = localStorage.getItem('verified');
        // const testingValue = localStorage.getItem('testing') === 'true';
        // setTesting(testingValue);
        // if (savedVerification) {
        //     setVerified(savedVerification);
        // }
        // if (savedAddress) {
        //     setNewWalletAddres(savedAddress);
        //     fetchBalance(savedAddress);
        // }
        // if (savedPrivKey) {
        //     setPrivKey(savedPrivKey);
        // }
    });
    
    return (
        <div className="homepage">
            <header className="fixed-header flex justify-between">
                <div className="flex items-center" >
                    <img src={require('./doginaldog-logo.png')} width={200} className="logo  py-4" alt="logo" />
                    <img src={require('./Shibexer_logo_white.webp')} width={200} className="logo  py-4" alt="logo" /> 
                </div>
                {/* {!verified && <button onClick={handleVerifyClick} className="verify-button">Verify</button> }

                {verified && <span className="header-text text-4xl font-bold">You are verified! </span> }

                {verified && <button onClick={handleLogout} className="logout-button">Logout</button> } */}
            </header>  
            <div className="content"> 
                {!updating && 
                    <>
                        <div className='md:flex justify-center min-h-screen w-full'>
                            <div className='md:w-1/2 p-4'> 
                                <h2 className="text-4xl font-bold "> Welcome to Shibexer </h2> 
                                <h2 className="text-3xl font-bold "> Made by: </h2> 
                                <img className='mx-auto' width={400} src={require('./doginal.png')} /> 
                                <p className=''> 
                                    Shibexer is the first tool developed to scan the Dogecoin blockchain for rare Shibs in real-time, enabling users to identify and isolate these unique tokens.
                                </p>
                                <br />  
                                <p className=''> 
                                    The original classifications are based on recurring events in Doge's protocol rules, such as new blocks, difficulty adjustments, halvings, and more. 
                                </p> 
                                <br /> 
                                <p className=''>
                                    Learn More Here: <a href="https://dogtoshi.gitbook.io/shibexer/">https://dogtoshi.gitbook.io/shibexer/ </a>
                                </p>
                                <br />
                                <form onSubmit={(e) => { e.preventDefault(); startDogeDetailsProcessing(); }}> 
                                    <div>
                                        <label htmlFor="address">
                                            Input Doge wallet 
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            id="address"
                                            value={address}
                                            onChange={handleChange}
                                            className="green-input"
                                        />
                                    </div> 
                                    <button type="submit" className="submit-button terminal-button">Start Scanning</button>
                                </form>

                                {loading && <div>Loading...</div>}
                                {isScanning && !isCleared && !dogeDetails && <div>Scanning for Rare Shibs...</div>}
                                {error && <div>Error: {error}</div>}
                                {dogeDetails &&   
                                    <>
                                        <ResultsDisplay 
                                            dogeDetails={dogeDetails}
                                            loading={loading}
                                            error={error}
                                            requestMade={requestMade}
                                        /> 

                                        <button onClick={clearResults} className="clear-results-button terminal-button">
                                            Clear Results
                                        </button>
                                    </>
                                }
                            </div>

                            <ShibesTables />  
                        </div>  
                    </>
                }

                { updating && <h1> Under maintenance </h1> }
                
            </div>  
        </div> 
    )
}

export default Homepage;