import React, { useState, useEffect } from "react"; 
import { fetchShibesData } from "./Homepage"; // import the function

const ShibesTables = () => {
    const [shibesData, setShibesData] = useState({
        uncommon: 0,
        blackUncommon: 0,
        rare: 0,
        blackRare: 0
    });

    useEffect(() => { 
        const fetchData = async () => {
            const data = await fetchShibesData();
            if (data) setShibesData(data);
        }

        fetchData();

        const interval = setInterval(fetchShibesData, 600000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className='md:w-1/2 my-10 md:my-5'>  
            <h3 className='text-4xl font-bold c-font'> Rodarmor Shibs </h3> 
            <p className='c-font'>
                Based on recurring events in Dogecoin’s protocol rules, i.e., new blocks, difficulty adjustments, and halvings.
            </p>
            <table className="table-auto border-collapse border mx-auto my-4 bg-black w-full">
                <thead>
                    <tr className="text-white text-left">
                        <th className="border border-gray-800 p-2 w-1/3">Shibetributes</th>
                        <th className="border border-gray-800 p-2">Description</th>
                        <th className="border border-gray-800 p-2">Current Supply</th>
                    </tr>
                </thead>
                <tbody >
                <tr className="bg-doginals ">
                    <td className="border p-2 text-black border-gray-800"> 
                        <div className="inline-flex items-center gap-1 rounded-[4px] px-[6px] py-[3px] text-[15px] font-semibold uppercase bg-purple">
                            <svg viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-[16px]">
                                <path fillRule="evenodd" clipRule="evenodd" d="M6.3 11.25 0 3.55 2.45.75h7.7l2.45 2.8-6.3 7.7Z" fill="#CB35F2"></path>
                            </svg>
                            Uncommon
                        </div>
                    </td>
                    <td className="border p-2 text-black border-gray-800"> The first Shib of a block. </td>
                    <td className="border p-2 text-black border-gray-800"> {shibesData.uncommon} </td>
                </tr>
                <tr className="bg-doginals">
                    <td className="border p-2 text-black border-gray-800">
                        <div className="inline-flex items-center gap-1 rounded-[4px] px-[6px] py-[3px] text-[15px] font-semibold uppercase bg-haze">
                            <svg viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-[16px]">
                                <path fillRule="evenodd" clipRule="evenodd" d="M6.3 11.25 0 3.55 2.45.75h7.7l2.45 2.8-6.3 7.7Z" fill="#6953E8"></path>
                            </svg>
                            Rare
                        </div>
                    </td>
                    <td className="border p-2 text-black border-gray-800"> The first Shib of a difficulty adjustment period.</td>
                    <td className="border p-2 text-black border-gray-800"> {shibesData.rare} </td>
                </tr>
                <tr className="bg-doginals ">
                    <td className="border p-2 text-black border-gray-800">
                        <div className="inline-flex items-center gap-1 rounded-[4px] px-[6px] py-[3px] text-[15px] font-semibold uppercase bg-green-c">
                            <svg viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-[16px]">
                                <path fillRule="evenodd" clipRule="evenodd" d="M6.3 11.25 0 3.55 2.45.75h7.7l2.45 2.8-6.3 7.7Z" fill="#009672"> </path>
                            </svg>
                            Epic
                        </div>
                    </td>
                    <td className="border p-2 text-black border-gray-800"> The first Shib of a new halving epoch. </td>
                    <td className="border p-2 text-black border-gray-800"> 5 </td>
                </tr>
                <tr className="bg-doginals ">
                    <td className="border p-2 text-black border-gray-800">
                        <div className="inline-flex items-center gap-1 rounded-[4px] px-[6px] py-[3px] text-[15px] font-semibold uppercase bg-legendary">
                            <svg viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-[16px]">
                                <path fillRule="evenodd" clipRule="evenodd" d="M6.3 11.25 0 3.55 2.45.75h7.7l2.45 2.8-6.3 7.7Z" fill="#f19100"></path>
                            </svg>
                            Legendary
                        </div>
                    </td>
                    <td className="border p-2 text-black border-gray-800"> The first Shib of a cycle. A cycle occurs every six halvings, when the halving and the difficulty adjustment coincide. </td>
                    <td className="border p-2 text-black border-gray-800"> 3 </td>
                </tr>
                <tr className="bg-doginals ">
                    <td className="border p-2 text-black border-gray-800">
                        <div className="inline-flex items-center gap-1 rounded-[4px] px-[6px] py-[3px] text-[15px] font-semibold uppercase bg-mythic">
                            <svg viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-[16px]">
                                <path fillRule="evenodd" clipRule="evenodd" d="M6.3 11.25 0 3.55 2.45.75h7.7l2.45 2.8-6.3 7.7Z" fill="#564890"></path>
                            </svg>
                            Mythic
                        </div>
                    </td>
                    <td className="border p-2 text-black border-gray-800">The first Shib of the genesis block.</td>
                    <td className="border p-2 text-black border-gray-800"> 1 </td>
                </tr> 
                </tbody>
            </table> 

            <h3 className='text-4xl font-bold c-font'> Black Shibs </h3> 
            <p className='c-font'>
                While Rodarmor Shibs mark the start of new events in Dogecoin, Black Shibs mark the end of events.
            </p>
            <table className='table-auto border-collapse border mx-auto my-4 bg-black w-full'>
                <thead>
                    <tr className="text-white text-left">
                        <th className="border p-2 border-gray-800 w-1/3">Shibetributes</th>
                        <th className="border p-2 border-gray-800">Description</th>
                        <th className="border p-2 border-gray-800">Current Supply</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="bg-doginals ">
                        <td className="border p-2 text-black border-gray-800">
                            <div className="inline-flex items-center gap-1 rounded-[4px] px-[6px] py-[3px] text-[13px] font-semibold uppercase bg-black">
                                <svg viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-[16px]">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M6.3 11.25 0 3.55 2.45.75h7.7l2.45 2.8-6.3 7.7Z" fill="#CB35F2"></path>
                                </svg>
                                Black Uncommon
                            </div>
                        </td>
                        <td className="border p-2 text-black border-gray-800">The last Shib of a block.</td>
                        <td className="border p-2 text-black border-gray-800">{shibesData.blackUncommon}</td>
                    </tr>
                    <tr className="bg-doginals ">
                        <td className="border p-2 text-black border-gray-800">
                            <div className="inline-flex items-center gap-1 rounded-[4px] px-[6px] py-[3px] text-[13px] font-semibold uppercase bg-black">
                                <svg viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-[16px]">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M6.3 11.25 0 3.55 2.45.75h7.7l2.45 2.8-6.3 7.7Z" fill="#6953E8"></path>
                                </svg>
                                Black Rare
                            </div>
                        </td>
                        <td className="border p-2 text-black border-gray-800">The last Shib of a difficulty adjustment period.</td>
                        <td className="border p-2 text-black border-gray-800"> {shibesData.rare} </td>
                    </tr>
                    <tr className="bg-doginals ">
                        <td className="border p-2 text-black border-gray-800">
                            <div className="inline-flex items-center gap-1 rounded-[4px] px-[6px] py-[3px] text-[13px] font-semibold uppercase bg-black">
                                <svg viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-[16px]">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M6.3 11.25 0 3.55 2.45.75h7.7l2.45 2.8-6.3 7.7Z" fill="#009672"></path>
                                </svg>
                                Black Epic
                            </div>
                        </td>
                        <td className="border p-2 text-black border-gray-800">The last Shib of a new halving epoch.</td>
                        <td className="border p-2 text-black border-gray-800"> 5 </td>
                    </tr>
                    <tr className="bg-doginals ">
                        <td className="border p-2 text-black border-gray-800">
                            <div className="inline-flex items-center gap-1 rounded-[4px] px-[6px] py-[3px] text-[13px] font-semibold uppercase bg-black">
                                <svg viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-[16px]">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M6.3 11.25 0 3.55 2.45.75h7.7l2.45 2.8-6.3 7.7Z" fill="#ffe99c"></path>
                                </svg>
                                Black Legendary
                            </div>
                        </td>
                        <td className="border p-2 text-black border-gray-800">The last Shib of a cycle. A cycle occurs every six halvings, when the halving and the difficulty adjustment coincide.</td>
                        <td className="border p-2 text-black border-gray-800"> 3 </td>
                    </tr>
                    <tr className="bg-doginals ">
                        <td className="border p-2 text-black border-gray-800">
                            <div className="inline-flex items-center gap-1 rounded-[4px] px-[6px] py-[3px] text-[13px] font-semibold uppercase bg-black">
                                <svg viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="h-[16px]">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M6.3 11.25 0 3.55 2.45.75h7.7l2.45 2.8-6.3 7.7Z" fill="#564890"></path>
                                </svg>
                                Black Mythic
                            </div>
                        </td>
                        <td className="border p-2 text-black border-gray-800">The last Shib of the genesis block.</td>
                        <td className="border p-2 text-black border-gray-800"> 1 </td>
                    </tr>
                </tbody>
            </table>  
        </div>
    )
}

export default ShibesTables;