import React from 'react';

const ResultsDisplay = ({ dogeDetails, loading, error, requestMade }) => {
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
 
  if (!dogeDetails && requestMade) {
    return <div>No details found for this wallet.</div>;
  }

  // Check if all categories have zero Shibes
  const allZeroShibes = dogeDetails && Object.values(dogeDetails).every(utxos => utxos.length === 0);

  if (allZeroShibes) {
    return <div>Sorry no rare shibes in this wallet.</div>;
  }

  const renderRows = data => {
    return Object.entries(data)
      .filter(([_, utxos]) => utxos.length > 0)
      .map(([category, utxos]) => (
        <div className='my-10 md:my-5'>
          <h3 className='text-4xl font-bold c-font'> {category} Shibs </h3>
          <table className="table-auto border-collapse border mx-auto my-4 bg-black w-full">
            <thead>
              <tr className="text-white text-left">
                <th className="border border-gray-800 p-2">Category</th>
                <th className="border border-gray-800 p-2">Shibe Count</th>
                <th className="border border-gray-800 p-2">TXIDs</th>
              </tr>
            </thead>
            <tbody className="bg-doginals">
              <tr>
                <td className="border p-2 text-black border-gray-800" style={{ verticalAlign: 'top' }}>{category}</td>
                <td className="border p-2 text-black border-gray-800" style={{ verticalAlign: 'top' }}>{utxos.length} Shibs</td>
                <td className="border p-2 text-black border-gray-800" style={{ verticalAlign: 'top' }}>
                  {utxos.map((utxo, index) => (
                    <div key={index} className="mb-2">
                      <a href={`https://dogechain.info/tx/${utxo.txid}`} target="_blank" rel="noopener noreferrer" className="text-green-400 hover:text-green-300">
                        {`${utxo.shibeNumber.slice(0, 12)}...`}
                      </a>
                    </div>
                  ))}
                </td>
              </tr>
            </tbody>
          </table>
        </div> 
    ));
  };

  return (
    <div className='w-full mt-5'> 
      <h2 className="text-4xl c-font font-bold my-5"> Shibe Stats </h2>
      {dogeDetails && renderRows(dogeDetails)} 
    </div>
  );
};

export default ResultsDisplay;
